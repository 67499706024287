<template>
  <div class="hvr-bob hvr-glow report-action-wrapper">
    <modal-form
      :title="$t('layout.report.new')"
      :fields="fields"
      :rules="rules"
      :loading="submitLoading"
      :visible="modalVisible"
      @close-modal="closeModal"
      @submit-form="submitForm"
      :cancelButton="cancelButton"
      :resetForm="resetForm"
      @update:resetForm="resetFormFn"
    />
    <sd-button class="btn-icon"
               type="default"
               shape="circle"
               :tooltip="$t('actions.download')"
               @click="openModal()">
      <sd-feather-icons type="download" :stroke="color" size="16"  />
    </sd-button>
  </div>
</template>

<script>
import {
  defineComponent, reactive, ref
} from 'vue';
import VueTypes from 'vue-types';
import {requestXlsx} from '@/helpers/downloadFile';
import moment from 'moment';
import ModalForm from '@/components/modals/ModalForm';
import {required} from '@/helpers/validationRules';
import _ from 'lodash';
import {theme} from '@/config/theme/themeVariables';
import {useI18n} from 'vue-i18n';
import {displayServerErrorsInToaster} from '@/helpers/toasters';
import i18n from '@/core/plugins/i18n';

export default defineComponent({
  name: 'ReportDownloadButton',
  props: {
    report: VueTypes.object.def({}),
    color: VueTypes.string.def(''),
    filterBy: VueTypes.object.def({}),
    filterByField: VueTypes.string.def('')
  },
  components: {
    ModalForm
  },
  setup(props) {
    const {t} = useI18n();
    const fields = reactive({
      fileName: {
        type: 'text',
        label: 'views.reports.fileName',
        fieldValue: _.replace(props.report.type, /_/g, ' '),
        placeholder: 'views.reports.fileName',
        disabled: false,
        size: 'large'
      },
      filterByLabel: {
        type: 'label',
        styles: {classes: 'mb-15 bold'},
        fieldValue: t('views.reports.filterBy') + ` ${props.report.filterByField}`,
        hidden: !props.report.filterByField
      },
      from: {
        type: 'datePicker',
        placeholder: 'views.reports.from',
        futureDate: false,
        styles: {xl: 12},
        hidden: !props.report.filterByField
      },
      to: {
        type: 'datePicker',
        placeholder: 'views.reports.to',
        futureDate: false,
        styles: {xl: 12},
        hidden: !props.report.filterByField
      }
    });
    const rules = reactive({
      fileName: [required]
    });

    const modalVisible = ref(false);
    const openModal = () => {
      modalVisible.value = true;
    };
    const closeModal = () => {
      modalVisible.value = false;
      resetForm.value = true;
    };
    const submitLoading = ref(false);
    const resetForm = ref(false);

    const submitForm = async (data) => {
      resetForm.value = false;
      submitLoading.value = true;

      const formData = new FormData();
      _.forEach(data, (value, key) => {
        formData.append(key, value || '');
      });
      submitLoading.value = false;
      if (formData.get('from') && formData.get('to') && moment(formData.get('from')) > moment(formData.get('to'))) {
        displayServerErrorsInToaster([i18n.global.t('messages.error.from_after_to')]);
        return;
      }
      if ((formData.get('from') && !formData.get('to')) || (!formData.get('from') && formData.get('to'))) {
        displayServerErrorsInToaster([i18n.global.t('messages.error.range_must_exists')]);
        return;
      }
      resetForm.value = true;
      download(formData);
      closeModal();
    };

    const download = (formData) => {
      const params = {
        report_type: props.report.type,
        file_name: formData.get(_.snakeCase('fileName')),
        filter_by: {
          from: formData.get('from'),
          to: formData.get('to')
        }
      };
      requestXlsx('reports/generate/predefined', params, `${formData.get(_.snakeCase('fileName'))}_${moment().format('YYYY-MM-DD HH:mm:ss')}`);
    };
    const resetFormFn = (value) => {
      resetForm.value = value;
    };
    const cancelButton = {type: 'secondary', visible: true};

    return {
      theme,
      fields,
      rules,
      modalVisible,
      openModal,
      closeModal,
      submitForm,
      submitLoading,
      cancelButton,
      resetForm,
      resetFormFn,
      download,
      moment
    };
  }
});
</script>
<style lang="scss" scoped>
  .bold {
    font-weight: 500;
  }
  .report-action-wrapper {
    text-align: center;
  }
</style>
