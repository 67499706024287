<template>
  <div>
    <table-style-wrapper>
      <table-wrapper class="table-responsive">
        <a-table :data-source="dataSource ? dataSource.data : []" :columns="columns" :rowKey="(record) => record.key"
                 :pagination="pagination" :loading="loading" @change="handleTableChange">
        <template #action="{record}">
          <report-download-button :report="record" :color="theme['primary-color']"/>
        </template>
        </a-table>
      </table-wrapper>
    </table-style-wrapper>
  </div>
</template>
<script>

import {TableStyleWrapper} from '@/components/tables/style';
import {usePagination} from 'vue-request';
import {TableWrapper} from '@/components/shared/styledComponents/Main';
import {theme} from '@/config/theme/themeVariables';
import ReportDownloadButton from '@/components/reports/ReportDownloadButton';
import {useStore} from 'vuex';
import {useI18n} from 'vue-i18n';
import {computed, defineComponent} from 'vue';

export default defineComponent({
  name: 'ReportsTable',
  components: {
    TableStyleWrapper,
    TableWrapper,
    ReportDownloadButton
  },
  setup() {
    const {getters, dispatch} = useStore();
    const {t} = useI18n();
    const columns = computed(() => [
      {
        title: t('layout.report.title'),
        dataIndex: 'title',
        key: 'title'
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        slots: {customRender: 'action'}
      }
    ]);
    const getReport = (params) => dispatch('getReport', {...params});
    const {
      data: dataSource, run, current, loading, pageSize
    } = usePagination(getReport, {
      defaultParams: [
        {
          perPage: 15
        }
      ],
      formatResult: () => getters.reports,
      pagination: {
        currentKey: 'page',
        pageSizeKey: 'perPage',
        totalKey: 'totalEntries'
      }
    });
    const pagination = computed(() => ({
      total: getters.reports?.totalEntries,
      current: current.value,
      pageSize: pageSize.value
    }));
    const handleTableChange = (pag, sorter) => {
      run({
        per_page: pag.pageSize,
        page: pag?.current,
        sortField: sorter.field,
        sortOrder: sorter.order
      });
    };
    return {
      theme,
      pagination,
      loading,
      dataSource,
      handleTableChange,
      columns
    };
  }
});
</script>
