<template>
  <div>
    <app-header :routes="routes" />
    <Main>
      <a-row :gutter="15">
        <a-col :xxl="24" :xl="24" :md="24" :xs="24">
          <sd-cards>
            <reports-table/>
          </sd-cards>
        </a-col>
      </a-row>
    </Main>
  </div>
</template>

<script>
import {
  defineComponent
} from 'vue';
import {Main} from '@/components/shared/styledComponents/Main';
import ReportsTable from '@/components/reports/Table';

export default defineComponent({
  name: 'Reports',
  components: {
    Main,
    ReportsTable
  },
  setup() {
    const routes = [{
      name: 'Reports',
      breadcrumbName: 'layout.report.index'
    }];

    return {
      routes
    };
  }
});
</script>
